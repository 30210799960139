import ABTests from "@/helpers/ABTests"
export default {
  data() {
    return {
      phoneListLinkForDropdown: [
        {
          phone: '+7 (800) 350-54-90',
          place: 'Россия',
          href: '78003505490',
        },
        {
          phone: '+7 (495) 118-44-44',
          place: 'Москва',
          href: '74951184444',
        },
      ],
      abFormSelectorVariant: null,
    }
  },
  computed: {
    showContacts() {
      return this.$route.path !== '/articles/videochaty-bez-registracii/'
    },
    headerBannerForProductsMessenger() {
      return this.$route.path == '/products/mess/'
    }
  },
  mounted() {
    if (this.$route.path == '/') {
      this.abFormSelectorVariant =
        ABTests.getABFormSelectorVariant('ab_meetings_filter')
       console.log(this.abFormSelectorVariant)
    }
  },
  methods: {
    sendMetricsSigninClient() {
      !!window.sendCommonEvent && window.sendCommonEvent('Client-entrance')
      setTimeout(() => {
        switch (this.$route.path) {
          case '/products/boards':
            return (window.location.href =
              'https://doski.mts-link.ru/dashboard')
          case '/products/messenger/':
            return (window.location.href = 'https://my.mts-link.ru/chats/')
          default:
            return (window.location.href ='https://my.mts-link.ru')
        }
      }, 500)
    },
  },
}
