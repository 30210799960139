<template>
  <div class="wrapper">
    <MTSHeaderDemoMobile />
    <section class="base-content">
      <Nuxt />
    </section>
    <ExternalIntegrations />
    <!--    <LazyHydrate never :trigger-hydration="isOpenFormSidebar">-->
    <!--    </LazyHydrate>-->
    <DataCollection />
    <div
      class="popmechanic-disable-flag"
      :class="{ true: hidePopmechanic }"
    ></div>
  </div>
</template>
<script>
import { initPageData, isNoindexNofollow } from '@/helpers/pageData'
import MTSHeaderDemoMobile from '~/components/Mobile/Common/MTSHeaderDemoMobile.vue'
import ExternalIntegrations from '~/components/Common/ExternalIntegrations/ExternalIntegrations.vue'
import DataCollection from '~/components/Common/DataCollection.vue'
import EventBus from '~/mixins/EventBus'

export default {
  name: 'innerMTS',
  components: {
    DataCollection,
    ExternalIntegrations,
    MTSHeaderDemoMobile,
  },
  mixins: [EventBus],
  data() {
    return {
      form: '',
      header: '',
      isShowCreateMeeting: false,
      isAutotest: false,
      isABTestEnabled: false,
      formName: undefined,
    }
  },
  head() {
    return {
      __dangerouslyDisableSanitizers: ['script'],
      script: [
        {
          innerHTML: JSON.stringify(this.generateLdPlusJSON()),
          type: 'application/ld+json',
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: 'https://mts-link.ru' + this.$route.path,
        },
      ],
      meta: [
        ...(isNoindexNofollow(this.$route.path)
          ? [{ name: 'robots', content: 'noindex, nofollow' }]
          : []),
      ],
    }
  },
  computed: {
    hidePopmechanic() {
      const route = this.$route.name
      return (
        route.includes('/tariffs') ||
        route.includes('/billing-registration-custom') ||
        route.includes('bill-item') ||
        this.$route.path === '/bill/'
      )
    },
  },
  mounted() {
    initPageData()
  },
  methods: {
    generateLdPlusJSON() {
      return {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: 'МТС Линк',
        alternateName: 'МТС Линк',
        url: 'https://mts-link.ru' + this.$route.path,
        logo: 'https://mts-link.ru/_nuxt/img/mts-link.svg',
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.wrapper {
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  padding: 56px 0 24px;
  overflow: hidden; // раскомментила
}

.base-content {
  position: relative;
  flex-grow: 1;
}
</style>
