var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('Transition',[(_vm.isOpenSidebar || _vm.isOpenFormSidebar)?_c('div',{staticClass:"overlay overlay--header-dropdown",on:{"click":function($event){_vm.closeSidebar(), _vm.closeFormSidebar()}}}):_vm._e()]),_vm._v(" "),_c('HeaderMobile',{on:{"openSidebar":_vm.openSidebar}}),_vm._v(" "),_c('HeaderPanelMobile',{attrs:{"is-a-b-test-enabled":_vm.isABTestEnabled},on:{"openSidebar":_vm.openSidebar}}),_vm._v(" "),_c('main',[_c('Nuxt',{on:{"open-popup":function($event){return _vm.openPopup()},"close-popup":function($event){return _vm.closePopup()}}})],1),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('LazyFooterMobile')],1),_vm._v(" "),_c('ExternalIntegrations'),_vm._v(" "),_c('LazyHydrate',{attrs:{"never":"","trigger-hydration":_vm.isOpenSidebar}},[_c('Transition',{attrs:{"name":"slide"}},[(_vm.isOpenSidebar)?_c('LazySidebarMobile',{on:{"closeSidebar":_vm.closeSidebar}}):_vm._e()],1)],1),_vm._v(" "),_c('DataCollection'),_vm._v(" "),_c('div',{staticClass:"popmechanic-disable-flag",class:{
      true:
        _vm.isOpenFormSidebar ||
        _vm.isOpenSidebar ||
        _vm.isShowCreateMeeting ||
        _vm.isAutotest,
    }})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }