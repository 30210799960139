<template>
  <div class="footer-menu">
    <div v-for="(column, index) in menu" :key="index" class="footer-menu__column">
      <div v-for="(item, index) in column" :key="index" class="footer-menu__column-item">
          <a
            :href="item.href"
            class="footer-menu__column-item-link"
            :rel="item.rel"
            :target="item.target"
          >
            {{ item.title }}
          </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterMenuDesktop',
  props: {
    menu: {
      type: Array,
      default: () => ([]),
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-menu {
  display: grid;
  grid-template-columns: repeat(2, max(200px));
  column-gap: 32px;

  @include screen-down('lg') {
    grid-template-columns: repeat(2, max(149px));
  }

  &__column {
    display: grid;
    row-gap: 16px;

    @include screen-down('lg') {
      row-gap: 12px;
    }

    &-item {

      &-link {
        width: max-content;
        color: $color-gray-1;
        text-decoration: none;
        font-size: 17px;
        line-height: 24px;

        &:hover {
          text-decoration: underline;
        }

        @include screen-down('lg') {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
