export default {
  data() {
    return {
      title: 'МТС Линк',
      description: `
        Сервисы для бизнес-коммуникаций и\u00A0совместной работы. Совещания, вебинары,
        корпоративный мессенджер, онлайн-доски и\u00A0обучающие курсы в\u00A0одной экосистеме
      `,
      menu: [
        [
          {
            title: 'О нас',
            href: '/about_service/',
            target: '',
            rel: '',
          },
          {
            title: 'Тарифы',
            href: '/tariffs/',
            target: '',
            rel: '',
          },
          {
            title: 'Контакты',
            href: '/contacts/',
            target: '',
            rel: '',
          },
          {
            title: 'Пресс-центр',
            href: '/news/',
            target: '',
            rel: '',
          },
          {
            title: 'Вакансии',
            href: 'https://job.mts-link.ru/',
            target: '_blank',
            rel: 'noreferrer nofollow noopener',
          },
          {
            title: 'Партнерство',
            href: '/partners/',
            target: '',
            rel: '',
          },
        ],
        [
          {
            title: 'База знаний',
            href: 'https://help.mts-link.ru/',
            target: '_blank',
            rel: 'noreferrer nofollow noopener',
          },
          {
            title: 'МТС Линк Медиа',
            href: '/blog/',
            target: '',
            rel: '',
          },
          {
            title: 'Обучающие материалы',
            href: 'https://learn.mts-link.ru/',
            target: '_blank',
            rel: 'noreferrer nofollow noopener',
          },
          {
            title: 'Мероприятия',
            href: '/events-calendar/',
            target: '',
            rel: '',
          },
          {
            title: 'Поддержка 24/7',
            href: '/faq_and_support/',
            target: '',
            rel: '',
          },
          {
            title: 'Bug Bounty',
            href: 'https://bugbounty.mts-link.ru/',
            target: '_blank',
            rel: 'noreferrer nofollow noopener',
          },
        ],
      ],
      links: [
        {
          title: 'Политика в отношении обработки персональных данных',
          href: '/privacy-policy/',
          target: '',
          rel: '',
        },
        {
          title: 'Деловая этика и комплаенс',
          href: '/komplaens-i-delovaya-etika/',
          target: '',
          rel: '',
        },
        {
          title: 'Правовая информация',
          href: '/policy/',
          target: '',
          rel: '',
        },
        {
          title: 'Карта сайта',
          href: '/sitemap/',
          target: '',
          rel: '',
        },
      ],
      bottom: `
        <div>© Webinar Group, 2008–2025. «МТС Линк», «Webinar», «We.Study», «Comdi» —</div>
        <div>товарные знаки, используемые Webinar Group</div>
      `,
      iconParams: {
        width: 44,
        height: 44,
        color: '#BBC1C7',
      },
    }
  },
  computed: {
    showLine() {
      return !this.$route.path.includes('/application')
    },
  },
  mounted() {
    this.updateIconSize()
    window.addEventListener('resize', this.updateIconSize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateIconSize)
  },
  methods: {
    updateIconSize() {
      this.iconParams.width = this.iconParams.height =
        window.innerWidth > 991 ? 44 : 32
    },
  },
}
