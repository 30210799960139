<template>
  <div class="error-page">
    <div class="container">
      <picture>
        <source :srcset="require('~/assets/img/error/img_1.webp')" />
        <img
          :src="require('~/assets/img/error/img_1.png')"
          alt="404"
          width="245"
          height="124"
          class="error-page__img"
        />
      </picture>
      <h2 class="error-page__content-title">Такой страницы нет</h2>
      <p class="error-page__content-description">
        Ссылка неверна или устарела.<br>
        Перейдите на&nbsp;главную страницу или&nbsp;напишите
        в&nbsp;нашу техподдержку
      </p>
      <VButtonLinkMTS href="/" class="error-page__btn --violet-gradient"
        >На главную страницу</VButtonLinkMTS
      >
      <VButtonMTS class="error-page__btn --grey" @click="handleClick"
        >Написать в техподдержку</VButtonMTS
      >
    </div>
  </div>
</template>
<script>
import VButtonLinkMTS from '~/components/Common/MTS/VButtonLinkMTS.vue'
import VButtonMTS from '~/components/Common/MTS/VButtonMTS.vue'
export default {
  name: 'ErrorPageMobile',
  components: { VButtonMTS, VButtonLinkMTS },
  methods: {
    handleClick() {
      window.carrotquest.open()
    },
  },
}
</script>
<style lang="scss" scoped>
.error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px 0 64px;
  max-width: 760px;
  margin: 0 auto;
  font-family: $font-mts-compact;
  color: $color-dark-2;
  text-align: center;

  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  &__content {
    &-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      margin-top: 32px;
      margin-bottom: 16px;
    }
    &-description {
      font-size: 17px;
      font-weight: 400;
      line-height: 24px;
      color: $color-gray-1;
    }
  }

  &__btn {
    display: block;
    padding: 14px;
    border-radius: 12px;
    width: 100%;
    max-width: 335px;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    &:first-of-type:not(button) {
      margin-top: 32px;
      margin-bottom: 16px;
    }
  }
}
</style>
