import ABTests from '@/helpers/ABTests'

export default {
  props: {
    isABTestEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isProgressBarShown: false,
      isAutotest: false,
      lastScrollTop: 0,
      formName:
        this.$route.path === '/'
          ? 'sticky_header_index'
          : 'sticky_header_other',
      abFormSelectorVariant: null,
    }
  },
  computed: {
    showContacts() {
      return this.$route.path !== '/articles/videochaty-bez-registracii/'
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    if (this.$route.path.includes('articles') && this.$route.params.item) {
      this.isProgressBarShown = true
    }

    if (this.$route.query.autotest === '1') {
      this.isAutotest = true
    } else {
      this.abFormSelectorVariant =
        ABTests.getABFormSelectorVariant('ab_meetings_filter')
    }
  },
  methods: {
    handleScroll() {
      const scrollTop = window.pageYOffset
      if (scrollTop < this.lastScrollTop && this.lastScrollTop > 119) {
        document.querySelector('#header-panel').classList.add('visible')
      } else {
        document.querySelector('#header-panel').classList.remove('visible')
      }
      // Механика работы прогресс-бара для статей (временно отключили)
      // if (this.isProgressBarShown) {
      //   const winScroll =
      //     document.body.scrollTop || document.documentElement.scrollTop
      //   const height =
      //     document.documentElement.scrollHeight -
      //     document.documentElement.clientHeight
      //   const scrolled = (winScroll / height) * 100
      //   this.$refs.progress.style.width = scrolled + '%'
      // }
      this.lastScrollTop = scrollTop
    },

    choiceHeaderButtonEvent(header, form, params = {}) {
      const VueScrollTo = require('vue-scrollto')
      if (document.getElementById('registration') !== null) {
        // если есть форма
        VueScrollTo.scrollTo('.order-form.order-platform-form')
      } else {
        this.showFormSidebar(header, form, params)
      }
    },

    showFormSidebar(header, form, params = {}) {
      this.$eventBus.$emit('show-sidebar', {
        form,
        header,
        params: params,
      })
    },

    sendMetricsSigninClient() {
      !!window.sendCommonEvent && window.sendCommonEvent('Client-entrance')
      setTimeout(() => {
        switch (this.$route.path) {
          case '/products/boards':
            return (window.location.href =
              'https://doski.mts-link.ru/dashboard')
          case '/products/messenger/':
            return (window.location.href = 'https://my.mts-link.ru/chats/')
          default:
            return (window.location.href =
              this.abFormSelectorVariant == '1'
                ? 'https://my.mts-link.ru'
                : 'https://mts-link.ru/user/entrance')
        }
      }, 500)
    },
  },
}
