<template>
  <div class="error-page">
    <div class="container">
      <picture>
        <source :srcset="require('~/assets/img/error/img_1.webp')" />
        <img
          :src="require('~/assets/img/error/img_1.png')"
          alt="404"
          width="448"
          height="223"
          class="error-page__img"
        />
      </picture>
      <div class="error-page__content">
        <h2 class="error-page__content-title">Такой страницы нет</h2>
        <p class="error-page__content-description">
          Ссылка неверна или устарела. Перейдите на&nbsp;главную страницу
          или&nbsp;напишите в&nbsp;нашу техподдержку
        </p>
      </div>
      <div class="error-page__btn-wrapper">
        <VButtonLinkMTS href="/" class="error-page__btn --violet-gradient"
          >На главную страницу</VButtonLinkMTS
        >
        <VButtonMTS class="error-page__btn --grey" @click="handleClick"
          >Написать в техподдержку</VButtonMTS
        >
      </div>
    </div>
  </div>
</template>
<script>
import VButtonLinkMTS from '~/components/Common/MTS/VButtonLinkMTS.vue'
import VButtonMTS from '~/components/Common/MTS/VButtonMTS.vue'
export default {
  name: 'ErrorPageDesktop',
  components: { VButtonMTS, VButtonLinkMTS },
  methods: {
    handleClick() {
      window.carrotquest.open()
    },
  },
}
</script>
<style lang="scss" scoped>
.error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 64px 0 128px;
  max-width: 780px;
  margin: 0 auto;
  font-family: $font-mts-compact;
  color: $color-dark-2;
  text-align: center;
  .container {
    padding-left: 10px;
    padding-right: 10px;

    @include screen-down('lg') {
      padding-left: 62px;
      padding-right: 62px;
    }
  }

  &__content {
    margin: 40px auto 0;

    @include screen-up('lg') {
      max-width: 484px;
    }

    &-title {
      font-size: 32px;
      font-weight: 500;
      line-height: 36px;
      margin-bottom: 16px;
    }

    &-description {
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      color: $color-gray-1;
    }
  }

  &__btn {
    flex: 1;
    max-width: 308px;
    padding: 14px 24px;
    border-radius: 12px;
    font-size: 20px;
    line-height: 24px;

    &-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 636px;
      column-gap: 20px;
      margin: 32px auto 0;
      @include screen-down('lg') {
        margin-top: 40px;
      }
    }
  }
}
</style>
