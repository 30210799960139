<template>
  <div class="wrapper">
    <Transition>
      <div
        v-if="isOpenSidebar || isOpenFormSidebar"
        class="overlay overlay--header-dropdown"
        @click="closeSidebar(), closeFormSidebar()"
      />
    </Transition>
    <HeaderMobile @openSidebar="openSidebar" />
    <HeaderPanelMobile
      :is-a-b-test-enabled="isABTestEnabled"
      @openSidebar="openSidebar"
    />
    <main>
      <Nuxt @open-popup="openPopup()" @close-popup="closePopup()" />
    </main>
    <LazyHydrate when-visible>
      <LazyFooterMobile />
    </LazyHydrate>
    <ExternalIntegrations />

    <LazyHydrate never :trigger-hydration="isOpenSidebar">
      <Transition name="slide">
        <LazySidebarMobile v-if="isOpenSidebar" @closeSidebar="closeSidebar" />
      </Transition>
    </LazyHydrate>

    <!--    <LazyHydrate never :trigger-hydration="isOpenFormSidebar">-->
    <!-- <Transition name="slide">
      <LazyFormSidebar v-if="isOpenFormSidebar" :form="form" :header="header" :form-name="formName"
        @closeFormSidebar="closeFormSidebar" />
    </Transition> -->

    <!--    </LazyHydrate>-->
    <DataCollection />
    <div
      class="popmechanic-disable-flag"
      :class="{
        true:
          isOpenFormSidebar ||
          isOpenSidebar ||
          isShowCreateMeeting ||
          isAutotest,
      }"
    />
  </div>
</template>
<script>
import LazyHydrate from 'vue-lazy-hydration'
import { initPageData, isNoindexNofollow } from '@/helpers/pageData'
import EventBus from '@/mixins/EventBus'
import HeaderMobile from '@/components/Mobile/Header/HeaderMobile.vue'
import HeaderPanelMobile from '@/components/Mobile/Header/HeaderPanelMobile.vue'
import ExternalIntegrations from '@/components/Common/ExternalIntegrations/ExternalIntegrations.vue'
import DataCollection from '@/components/Common/DataCollection.vue'
import LazyFooterMobile from '~/components/Mobile/Footer/Lazy/LazyFooterMobile.vue'
import LazySidebarMobile from '~/components/Mobile/Common/Lazy/LazySidebarMobile.vue'
import LazyFormSidebar from '~/components/Common/Form/Lazy/LazyFormSidebar.vue'

export default {
  name: 'primary-mobile',
  components: {
    LazyFormSidebar,
    LazySidebarMobile,
    LazyFooterMobile,
    DataCollection,
    ExternalIntegrations,
    HeaderPanelMobile,
    HeaderMobile,
    LazyHydrate,
  },
  mixins: [EventBus],
  data() {
    return {
      form: '',
      isOpenSidebar: false,
      isOpenPopup: false,
      isOpenFormSidebar: false,
      isShowCreateMeeting: false,
      isAutotest: false,
      isABTestEnabled: false,
      abFormSelectorVariant: '0',
      formName: undefined,
    }
  },
  head() {
    return {
      __dangerouslyDisableSanitizers: ['script'],
      script: [
        {
          innerHTML: JSON.stringify(this.generateLdPlusJSON()),
          type: 'application/ld+json',
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: 'https://mts-link.ru' + this.$route.path,
        },
      ],
      meta: [
        ...(isNoindexNofollow(this.$route.path)
          ? [{ name: 'robots', content: 'noindex, nofollow' }]
          : []),
      ],
      bodyAttrs: {
        class: this.isOpenFormSidebar || this.isOpenSidebar ? 'no-scroll' : '',
      },
    }
  },
  watch: {
    '$route.path'() {
      this.closeSidebar()
    },
  },
  mounted() {
    initPageData()

    if (this.$route.query.autotest === '1') {
      this.isAutotest = true
    }

    if (this.$route.hash) {
      this.$scrollTo(this.$route.hash, 500, { offset: 0 })
    }
  },
  methods: {
    openSidebar() {
      this.isOpenSidebar = true
    },
    closeSidebar() {
      this.isOpenSidebar = false
    },

    __onShowFormSidebar(params) {
      this.isOpenFormSidebar = true
      this.form = params.form
      this.header = params.header
      this.isABTestEnabled = params?.params?.isABTestEnabled
      this.formName = params?.params?.formName
    },

    closeFormSidebar() {
      this.isOpenFormSidebar = false
    },

    openPopup() {
      this.isShowCreateMeeting = true
    },

    closePopup() {
      this.isShowCreateMeeting = false
    },

    generateLdPlusJSON() {
      return {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: 'МТС Линк',
        alternateName: 'МТС Линк',
        url: 'https://mts-link.ru' + this.$route.path,
        logo: 'https://mts-link.ru/_nuxt/img/mts-link.svg',
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.wrapper {
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  overflow-x: hidden;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 499;
  background-color: rgba(29, 32, 35, 0.4);
  backdrop-filter: blur(4px);
  transition: 0.3s;
}

.v-enter {
  opacity: 0;
}

.v-leave-to {
  opacity: 0;
  visibility: hidden;
}

.slide-enter-active {
  transform: translateX(100%);
  transition: all 0.2s ease-out;
}

.slide-leave-active {
  transition: all 0.2s ease-out;
}

.slide-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>
